<!--
 * @Description:车辆用户管理系统管理系统 实名认证 cuUserApprove
 * @Author: zhoucheng
 * @Date: 2021-03-16 14:12:45
 * @LastEditTime: 2022-01-14 15:32:09
 * @LastEditors: zhoucheng
-->
<template>
  <div>
    <!-- 实名认证搜索表单 -->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label="手机号"
                          label-width="90px">
              <el-input v-model="searchForm.phoneNumber"
                        placeholder="请输入手机号">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="认证时间"
                          label-width="90px">
              <el-date-picker v-model="times"
                              type="daterange"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="姓名"
                          label-width="90px">
              <el-input v-model="searchForm.realName"
                        placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="身份证号"
                          label-width="90px">
              <el-input v-model="searchForm.cardNo"
                        placeholder="请输入身份证后四位"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-row class="topButton">
              <el-button @click="handleClickSearch"
                         type="primary">查 询</el-button>
              <el-button @click="resetForm"
                         type="info"
                         style="margin-left:17px">重 置</el-button>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <!-- 实名认证表格 -->
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>实名认证</span>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作"
                             align="center">
              <template slot-scope="scope">
                <el-col :span="12">
                  <el-button @click="handleClickDetails(scope.row)"
                             type="text"
                             size="medium">详情</el-button>
                </el-col>
                <el-col :span="12">
                  <el-button @click="handleClickDelete(scope.row)"
                             type="text"
                             size="medium">删除</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 用户认证详情弹窗 -->
    <el-dialog title="用户认证详情"
               :visible.sync="distributeVisible"
               append-to-body>
      <el-form ref="popForm"
               :model="popForm"
               label-width="120px">
        <div class="tanItem">
          <el-row class="popRow">
            <el-form-item label="手机号"
                          prop="phoneNumber">
              <el-input v-model="popForm.phoneNumber"
                        readonly></el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="identifyTime"
                          label="认证时间">
              <el-input v-model="popForm.identifyTime"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item label="姓名"
                          prop="realName">
              <el-input v-model="popForm.realName"
                        readonly></el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="cardNo"
                          label="身份证号">
              <el-input v-model="popForm.cardNo"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="province"
                          label="省">
              <el-input v-model="popForm.province"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="city"
                          label="市">
              <el-input v-model="popForm.city"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="district"
                          label="县">
              <el-input v-model="popForm.district"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="sex"
                          label="性别">
              <el-input v-model="popForm.sex"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>

          <el-row class="popRow">
            <el-form-item prop="birthday"
                          label="出生日期">
              <el-input v-model="popForm.birthday"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
        </div>
        <el-row class="popRow">
          <el-form-item prop="area"
                        label="具体地址">
            <el-input v-model="popForm.area"
                      readonly>
            </el-input>
          </el-form-item>
        </el-row>
        <el-row style="margin-left:94%; margin-top:2%">
          <el-button type="info"
                     @click="handleClickReturn">返回</el-button>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import $commonsJs from '@/common/commonsJs.js'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      tableConfig: { border: true, },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: {
        pageNum: 1,
        pageSize: 10,
      },
      tableColumnList: [
        {
          prop: "phoneNumber",
          label: "手机号"
        },
        {
          prop: "realName",
          label: "姓名",

        },
        {
          prop: "cardNo",
          label: "身份证号"
        },
        {
          prop: "identifyTime",
          label: "认证时间"
        },
      ],
      tableList: {
        list: [],
      },
      pageNum: 1,// 初始化分页
      pageSize: 15,// 初始化分页
      // 认证时间控件
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      // 顶部条件搜索表单
      searchForm: {
        startTime: "",
        endTime: "",
        realName: "",
        cardNo: "",
        phoneNumber: ""
      },
      times: [],// 认证时间
      popForm: {},// 详情弹出框表单
      distributeVisible: false,// 详情弹出框是否打开
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryTableList()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 列表查询
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum
      this.searchForm['pageSize'] = this.pageSize
      this.$cuUserApprove.queryUserIdentifyRecordList(this.searchForm).then(res => {
        res.resultEntity.list.forEach(item => {
          if (item.phoneNumber) {
            item.phoneNumber = $commonsJs.phoneNumberDesensitizate(item.phoneNumber)
          }
        })
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 顶部查询按钮
    handleClickSearch () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      if (this.times) {
        this.searchForm.startTime = this.times[0]
        this.searchForm.endTime = this.times[1]
      }
      else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.queryTableList()
    },
    // 重置按钮
    resetForm () {
      this.searchForm = {}
      this.times = []
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    // 点击表格详情
    handleClickDetails (row) {
      this.distributeVisible = true
      this.popForm = row
    },
    // 点击表格删除
    handleClickDelete (row) {
      this.$confirm('是否确定删除该认证?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cuUserApprove.deleteUserIdentifyRecord({ recordId: row.recordId }).then(() => {
          this.$message({ message: '删除成功', type: 'success' });
          this.queryTableList()
        })
      })
    },
    // 点击事件返回按钮
    handleClickReturn () {
      this.distributeVisible = false
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
// 顶部查询条件
.topSelectTwoLine {
  width: 100%;
  @include background('blockBg');
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 21px;
  .topSecondLine {
    margin-top: 19.7px;
  }
  .topButton {
    display: flex;
    padding-left: 91px;
    margin-top: 17px;
  }
}
// 表格样式
.tableRow {
  width: 100%;
  @include background('blockBg');
  margin-top: 16px;
  padding-left: 20.2px;
  padding-right: 20.2px;
  padding-top: 5px;
  .tableTitle {
    height: 32px;
    margin-top: 15px;
    .tableTitleButton {
      float: right;
      margin-bottom: 10px;
    }
  }
  .tableContent {
    margin-top: 10px;
  }
}
// 弹窗样式
.tanItem {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .el-form-item {
    margin-bottom: 20px;
  }
}
</style>